import React from "react";

import { Layout } from "../components/layout";
import { SEO } from "../components/seo";
const AboutPage: React.FC<{ location: Location }> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="About me - hayashi tech blog" />
      <h1>About</h1>

      <h3>このサイトは何？</h3>
      <p>
        普段あんまりweb触らないので、webの勉強のために作りました。内容は大体学習したことのメモ書きです。そもそも作ることが主軸にあるので、更新頻度はそこまで高くないです。気が向いた時に気が向いたことを書きます
      </p>

      <h3>書いてる人</h3>
      <p>
        林です。福岡県出身。現在文京区在住。好きな卍解は"黄煌厳霊離宮"です。普段はReact
        Nativeでアプリを作っています。フロントエンドが得意ですが、成り行き上フロントエンドを触る機会に恵まれているだけで、特にこだわりはありません。
        これからはバックエンドも頑張りたいです。
      </p>

      <h4>すきなもの</h4>
      <ul>
        <li>・React(なんだかんだ好き)</li>
        <li>・野球（鷹/燕を特に応援してます）</li>
        <li>・読書（後述）</li>
        <li>・漫画（ジャンプとヤンジャンとマガジンは毎週読んでます）</li>
        <li>・日本酒</li>
      </ul>

      <h5>読書</h5>
      <p>
        <a
          href="https://seen-railway-bad.notion.site/8bf3f95156fc4dcc8a9cf0c305163016"
          target="_blank"
        >
          ここ（外部リンク）
        </a>
        に読んだ本を雑にまとめてます
      </p>

      <h3>スキルセット</h3>
      <h4>仕事で使ったやつ</h4>
      <p>JavaScript / TypeScript / React / React Native / Next.js / Redux / Redux-saga /</p>
    </Layout>
  );
};

export default AboutPage;
